import { Component, NgModule, ViewContainerRef } from '@angular/core';
//import { BrowserModule } from '@angular/platform-browser';
//import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import { ButtonModule, PasswordModule, InputTextModule } from 'primeng/primeng';
//import { ToastsManager, Toast } from 'ng2-toastr/ng2-toastr';
import { PointService } from '../../service/service';

@Component({
    selector: 'ngx-logout',
    templateUrl: './logout.html',
})
export class LogoutComponent {

    constructor(private service: PointService) {
        document.getElementById('nb-global-spinner').style.display = 'none';
    }

    ngOnInit() {
        console.log("logged out");
        localStorage.setItem("'authenticationToken'", "");
        localStorage.setItem("userRole", "");
        (window as any).location = "/#/auth/login";
    }
}

