import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { PointService } from '../../service/service';
import {DomSanitizer} from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// const URL = '/api/';

@Component({
    selector: 'file-uploader',
    templateUrl: './file-upload.component.html'
})
export class UploaderComponent implements OnInit {
    ngOnInit() { }
    public uploading: boolean = false;
    @Output() onUpload: EventEmitter<any> = new EventEmitter();
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    @Output() onDurationReady: EventEmitter<any> = new EventEmitter();
    @Input() type: string;
    @Input() target: string;
    @Input() url: string;
    @Input() width: number;
    public duration: number;

    @ViewChild('ng2FileSelect', {static: false}) ng2FileSelect;
    @ViewChild('audioComponent', {static: false}) audioComponent: ElementRef;
    public ngOnChanges(changes: SimpleChanges) { }

    constructor(private service: PointService, private sanitizer: DomSanitizer, private modalService: NgbModal) {
        var that = this;
        this.sanitizer = sanitizer;
        this.uploader.onBuildItemForm = (fileItem, form) => {
            form.append('target', this.target);
            return { fileItem, form };
        };
        this.uploader.onSuccessItem = (item, resp, status, headers) => {
            that.uploader.queue.splice(0, 1);
            that.uploading = false;

            let parsedResponse = JSON.parse(resp).data;
            let result = {
                url: parsedResponse['url'] ? parsedResponse['url'] : parsedResponse['media']
            };

            that.url = result.url;

            if (that.type === "audio") {
                result['duration'] = that.duration;
            }

            that.onUpload.emit(result);
        }
    }
    public uploader: FileUploader = new FileUploader({ url: this.service.baseURL + this.service.endpoints.fileUpload, headers: this.headers });
    /*
      public get item() {
        return this.uploader.queue;
      }
    */
    public get headers() {
        let ret = [];
        let json = this.service.getHeaders().toJSON();
        for (let key in json) {
            ret.push({ name: key, value: json[key][0] });
        }
        return ret;
    }
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    public onFileSelected(file) {
      if(this.type === 'uploader') {
        var _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.src = _URL.createObjectURL(file[0]);
        const extension = file[0].name.split('.').pop().toLowerCase();
        img.onload = (event) => {
          if(extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
            alert("Image extension should be jpg, jpeg of png");
            return;
          } else if((img.width / img.height !== 1) && ((img.width / img.height) !== (16 / 9))) {
            alert("Image ratio should be 1:1 or 16:9");
            return;
          } else {
            this.uploading = true;
            this.uploader.queue[0].upload();
          }
        }

        } else {
        this.uploading = true;
        this.uploader.queue[0].upload();
      }

    }

    public onDeleteFile() {
        this.url = undefined;
        this.onDelete.emit();
        /*let that = this;
        let serviceUrl = this.service.URLs.admin + this.service.endpoints.file;
        this.service.httpDelete(serviceUrl, { url: this.url })
          .then(response => {
            that.url = undefined;
          })
          .catch(error => { })*/
    }

    public videoUrl() {
        return this.sanitizer.bypassSecurityTrustResourceUrl('https://aka.ms/ampembed?autoplay=false&url=' + this.url);
    }

    onClickVideo(targetModal) {
        this.modalService.open(targetModal, { size: 'lg', container: 'nb-layout' });
        let iframe = document.getElementById('iframe');
        iframe['src'] = '//aka.ms/ampembed?autoplay=true&url=' + this.url;
    }

    onClickCloseVideoPlayer() {
        this.modalService.dismissAll();
    }


}
