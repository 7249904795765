import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './file-upload.component'
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';

import { FileUploadModule } from 'ng2-file-upload';
import { PointService } from '../../service/service';

@NgModule({
  declarations: [UploaderComponent],
  imports: [
    CommonModule, FileUploadModule
  ],
  exports: [
    UploaderComponent, CommonModule   
  ]
  
})
export class FileUploaderModule { }
