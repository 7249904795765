import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { Utils } from '../helpers/utils';
import { reject } from 'q';

const version = 'v1';
export const baseURL = environment.serviceUrl + '/api/' + version + '/en/';


export const endpoints = {
    session: 'session',
    allChallenges: 'challenges/all',
    challenges: 'challenges',
    featuredChallenges: 'categories/5d8344fde7ba4c68b40c0462/summary',
    quizes: 'quizes',
    questions: 'quizes/questions',
    informations: 'quizes/informations',
    trivia: 'trivia/questions',
    tags: 'trivia/tags',
    triviaCategories: 'trivia/categories',
    categories: 'categories',
    fileUpload: 'files',
    users: 'users',
    posts: 'posts',
    abusedPosts: 'posts/abuses',
    comments: 'posts/comments',
    materials: 'materials',
    reports: 'reports',
    skills: 'skills',
    concepts: 'concepts',
    games: 'games',
    achievements: 'achievements',
    characters: 'characters',
    activities: 'activities',
    products: 'purchases/products',
    productCategories: 'purchases/products/categories',
    adventures: 'adventures',
    allAdventures: 'adventures/all',
    adventureGroups: 'adventures/groups',
    allAdventureGroups: 'adventures/groups/all',
    waitLists: 'adventures/groups/wait-lists',
    forums: 'forums/topics',
    allForumTopics: 'forums/topics/all',
    forumComments: 'forums/topics/comments',
    subscriptionsPromoCodes: '/subscriptions/promo-codes',
    biteSizeContents: '/bite-size-contents',
    allBiteSizeContents: '/bite-size-contents/all',
}

export const NotAuthenticated = 'NotAuthenticated';

@Injectable()
export class PointService {
    private username: string;
    private password: string;
    public endpoints = endpoints;
    public baseURL = baseURL;
    constructor(private http: Http, public utils: Utils) { }

    public getHeaders(additionalHeader?: { key: string, value: string }[]): Headers {
        let token = localStorage.getItem('authenticationToken');
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        headers.append('ngrok-skip-browser-warning', '1');
        if (additionalHeader) {
            for (let add of additionalHeader) {
                headers.append(add.key, add.value);
            }
        }
        return headers;
    }

    public httpPost(endpoint, body, isWithoutHeaders?): Promise<any> {
        let headers = isWithoutHeaders ? undefined : { headers: this.getHeaders() };
        this.utils.showLoading();
        return this.http.post(baseURL + endpoint, body, headers).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpPut(endpoint, body): Promise<any> {
        this.utils.showLoading();
        return this.http.put(baseURL + endpoint, body, { headers: this.getHeaders() }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpPatch(endpoint, body): Promise<any> {
        this.utils.showLoading();
        return this.http.patch(baseURL + endpoint, body, { headers: this.getHeaders() }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpDelete(endpoint, body?): Promise<any> {
        this.utils.showLoading();
        return this.http.delete(baseURL + endpoint, { headers: this.getHeaders(), body: body, }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public httpGet(endpoint: string, queryStringObj?: any, requiredFields?: any): Promise<any> {
        this.utils.showLoading();
        let queryString = this.objectToQuerystring(queryStringObj);
        return this.http.get(baseURL + endpoint + queryString, { headers: this.getHeaders(requiredFields) }).toPromise()
            .then(this.returnResponse).catch(this.handleError);
    }

    public returnResponse(res) {
        if (res.status === 200) {
            let body;
            try {
                body = JSON.parse(res._body);
            } catch (error) {
                body = res._body;
            }
            // hide loading
            document.getElementById('body').classList.remove('loading-active');

            return body.data || body || {};
        } else {
            return reject('Something bad happened; please try again later.');
        }
    }

    private handleError(error) {
        // hide loading
        document.getElementById('body').classList.remove('loading-active');
        console.log('error handler', error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            if (error.status === 401) {
                localStorage.setItem('authenticationToken', '');
                (window as any).location = '/#/login';
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        let errorBody
        try {
            errorBody = JSON.parse(error._body);
        } catch (error) {
            errorBody = error._body;
        }
        // return an observable with a user-facing error message
        return reject(errorBody.message || 'Something bad happened; please try again later.');
    };

    private objectToQuerystring(obj) {
        if (obj) {
            let queryString = '?';
            for (let key of Object.keys(obj)) {
                if (obj[key] !== '') {
                    if (queryString !== '?') queryString += '&';
                    queryString += key + '=' + encodeURIComponent(obj[key]);
                }
            }
            return queryString === '?' ? '' : queryString;
        } else { return ''; }
    }

    public callHttpHead(endpoint) {
        return this.http.head(endpoint, {});
    }

    public isAuthenticated() {
        let token = localStorage.getItem('authenticationToken');
        return Boolean(token);
    }

}
