import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { PointService } from '../../service/service';

// const URL = '/api/';

@Component({
  selector: 'hello-heading',
  templateUrl: './shared.component.html'
})
export class SharedComponent {
  

  constructor() {
    var that = this;
  }

}